export const contacts = [
  {
    name: 'Lassi-Pekka Taskinen',
    title: 'CEO',
    email: 'lassi-pekka.taskinen@sorvimo.com',
    mobile: '+358 50 325 7986',
    linkedIn: 'https://fi.linkedin.com/in/lassi-pekka-taskinen-5b1477b0',
    imageUrl: '/images/contacts/lassi-pekka_taskinen.png',
  },
  {
    name: 'Jan Lipponen',
    title: 'Development manager',
    email: 'jan.lipponen@sorvimo.com',
    mobile: null,
    linkedIn: null,
    imageUrl: null,
  },
  {
    name: 'Lasse Muttilainen',
    title: 'Senior application specialist',
    email: 'lasse.muttilainen@sorvimo.com',
    mobile: null,
    linkedIn: null,
    imageUrl: '/images/contacts/lasse_muttilainen.png',
  },
  {
    name: 'Ilari Federley',
    title: 'Structural analyst',
    email: 'ilari.federley@sorvimo.com',
    mobile: null,
    linkedIn: 'https://www.linkedin.com/in/ilari-federley-0a4683136/',
    imageUrl: '/images/contacts/ilari_federley.png',
  },
  {
    name: 'Marsel Garifullin',
    title: 'Structural analyst',
    email: 'marsel.garifullin@sorvimo.com',
    mobile: null,
    linkedIn: null,
    imageUrl: '/images/contacts/marsel_garifullin.png',
  },
  {
    name: 'Iiro Vuorio',
    title: 'Application specialist',
    email: 'iiro.vuorio@sorvimo.com',
    mobile: null,
    linkedIn: null,
    imageUrl: '/images/contacts/iiro_vuorio.png',
  },
];

export const experts = [
  {
    name: 'Markku Heinisuo',
    areaOfExpertise: 'Construction engineering',
    email: 'markku.heinisuo@sorvimo.com',
    mobile: '+358 40 896 8526',
    linkedIn: null,
    imageUrl: '/images/contacts/markku_heinisuo.png',
  },
  {
    name: 'Kristo Mela',
    areaOfExpertise: 'Construction engineering',
    email: 'kristo.mela@sorvimo.com',
    mobile: '+358 40 849 0563',
    linkedIn: 'https://fi.linkedin.com/in/kristo-mela-a848b98',
    imageUrl: '/images/contacts/kristo_mela.png',
  },
  {
    name: 'Sami Pajunen',
    areaOfExpertise: 'Mechanical and construction engineering',
    email: 'sami.pajunen@sorvimo.com',
    mobile: '+358 40 849 0571',
    linkedIn: null,
    imageUrl: '/images/contacts/sami_pajunen.png',
  },
];

export const company = {
  name: 'Sorvimo Optimointipalvelut Oy',
  address: 'Korkeakoulunkatu 7 (Kampusareena)',
  postalCode: '33720',
  city: 'Tampere',
  country: 'Finland',
  businessId: '2780865-9',
  email: 'sorvimo@sorvimo.com',
  linkedInUrl: 'https://www.linkedin.com/company/sorvimo-optimointipalvelut-oy',
};

export const recruitingFi = false;
export const recruitingEn = false;
